vue
<template>
    <div>
        <div class="p-grid">
            <div class="p-col-12 p-d-flex p-jc-center">
                <p class="title-bold">Neuromoduladores para arrugas de expresión</p>
            </div>
        </div>

        <div class="p-grid">
            <div class="p-col-12 p-d-flex p-jc-center">
                <img class="img-fluid" alt="rinomodelación" src="../../assets/Neromodulares-frente.svg" />
            </div>

            <div class="p-col-10 p-offset-1 p-d-flex p-jc-center">
                <Card styleClass="p-card-shadow">
                    <template #content>
                        <p>Los <span class="p-text-bold">neuromoduladores</span>, son tratamientos cosméticos no invasivos
                            utilizados para combatir las arrugas de expresión. Funcionan bloqueando temporalmente las
                            señales nerviosas que provocan la contracción muscular, suavizando así las líneas y arrugas
                            faciales, como las patas de gallo o las líneas de la frente. Este procedimiento es rápido y
                            seguro, con resultados visibles en unos días y mínimas molestias. Los efectos suelen durar de 3
                            a 6 meses, y se puede repetir según sea necesario para mantener una apariencia fresca y
                            rejuvenecida sin cirugía.</p>

                        <span class="p-text-bold">1. ¿Son seguros los neuromoduladores?</span>
                        <p>Sí, los neuromoduladores, son seguros cuando se
                            administran por profesionales capacitados. Tienen un historial comprobado de seguridad en
                            medicina estética.</p>

                        <span class="p-text-bold">2.¿Cuánto tiempo duran los resultados?</span>
                        <p>Los resultados de los neuromoduladores suelen durar de
                            3 a 6 meses, pero esto puede variar según la persona y la técnica utilizada.</p>

                        <span class="p-text-bold">3.¿Es doloroso el procedimiento?</span>
                        <p>El procedimiento es en general poco doloroso, y se
                            utiliza anestesia tópica si es necesario para minimizar cualquier molestia.</p>

                        <span class="p-text-bold">4.¿Puedo volver a mi rutina diaria de inmediato?</span>
                        <p>Sí, puede volver a sus actividades normales después
                            del tratamiento. Se recomienda evitar ejercicios intensos y acostarse durante unas horas.</p>

                        <span class="p-text-bold">5.¿Qué áreas del rostro se pueden tratar?</span>
                        <p>Los neuromoduladores se utilizan comúnmente en áreas
                            como la frente, entrecejo, patas de gallo y el cuello para suavizar arrugas de expresión y
                            prevenir el envejecimiento.</p>
                    </template>

                    <template #footer>
                        <span>Para más información no dude en ponerse en contacto con nuestro
                            Centro médico al 637 857 384.</span>
                    </template>
                </Card>
            </div>
        </div>
    </div>
</template>

<script>
import Card from 'primevue/card';
export default {
    name: 'RinomodelacionVue',
    data() {
        return {
            // Propiedades de datos del componente
        };
    },
    components: {
        Card
    },
    methods: {
        // Métodos del componente
    },
    computed: {
        // Propiedades computadas del componente
    },
    created() {
        // Lógica que se ejecuta cuando el componente se crea
    },
    mounted() {
        // Lógica que se ejecuta cuando el componente se monta en el DOM
    },
    destroyed() {
        // Lógica que se ejecuta cuando el componente se destruye
    },
};
</script>

<style scoped>
.title-bold {
    font-size: 30px;
    font-weight: bold
}

.img-fluid {
    width: 100%;
    height: auto;
}

@media (min-width: 576px) {
    .img-fluid {
        max-width: 50%;
        height: auto;
    }
}
</style>